/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@fortawesome/fontawesome-free/css/all.css";

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    background-color: #071325;
    cursor: default;
    font-family: 'Roboto', sans-serif;
}

.mat-tooltip {
    font-size: 14px;
}

.wpcc-container {
    max-width: 1000px;
    margin: auto;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: transparent; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c9d4e2; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #969eaa; 
}